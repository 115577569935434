import 'slick-carousel';

$(document).ready(function () {

  $(function () {
    // トップページ　お知らせスライダー
    $('#announcement-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      cssEase: "ease-out",
      arrows: false,
      dots: true,
      appendDots: $('.p-announcement-slider__dots-nav'),
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
    });

    // トップページ　ローテーションバナースライダー
    $('#rotation-bnr-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      cssEase: "ease-out",
      arrows: true,
      dots: false,
      slidesToShow: 1,
      centerMode: true,
      variableWidth: false,
      prevArrow: '<img src="./assets/images/front/under_rotation_left.png" class="slide-arrow prev-arrow">',
      nextArrow: '<img src="./assets//images/front/under_rotation_right.png" class="slide-arrow next-arrow">',
    });

    // 所属選手詳細ページ　写真スライダー
    $('#homeplayer-detail-slider').slick({
      autoplay: true,
      autoplaySpeed: 3000,
      speed: 1000,
      cssEase: "ease-out",
      arrows: false,
      dots: true,
      appendDots: $('.p-homeplayer-detail-slider__dots-nav'),
      slidesToShow: 1,
      centerMode: true,
      variableWidth: true,
    });
  });

});

$(function () {

  // 共通　フォントサイズ
  const storage = window.sessionStorage;
  let fontSize = 16;
  if(storage.getItem('fontSize')) {
    fontSize = parseFloat(storage.getItem('fontSize'));
  }
  setFontSize();

  $('#fontSmall').on('click', () => {
    fontSize = 16;
    setFontSize();
  });

  $('#fontMidium').on('click', () => {
    if(fontSize != 18) {
      fontSize = 18;
      setFontSize();
    }
  });

  $('#fontLarge').on('click', () => {
    if(fontSize != 20) {
      fontSize = 20;
      setFontSize();
    }
  });

  function setFontSize() {
    storage.setItem('fontSize', fontSize);
    $('html').css({'font-size': `${fontSize}px`});
    if(fontSize == 20) {
      $('#fontSmall').removeClass('is-active');
      $('#fontMidium').removeClass('is-active');
      $('#fontLarge').addClass('is-active');
    } else if(fontSize == 18) {
      $('#fontSmall').removeClass('is-active');
      $('#fontMidium').addClass('is-active');
      $('#fontLarge').removeClass('is-active');
    } else {
      $('#fontSmall').addClass('is-active');
      $('#fontMidium').removeClass('is-active');
      $('#fontLarge').removeClass('is-active');
    }
  }

  // 共通　ハンバーガーメニュー
  $('.p-hamburger-btn').on("click", function () {
    $(this).toggleClass('is-active');
    $(this).children('.is-border').toggleClass('is-active');
    $(this).children('.is-text').toggleClass('is-active');

    const label = $('.p-global-nav');
    const container = $('.p-global-nav__container');

    if (label.attr("data-is-active") === "false") {
      const targetHeight = container.outerHeight();
      label.height(targetHeight);
      setTimeout(function () {
        label.height("auto");
        label.attr("data-is-active", "true");
        $('.p-global-nav').toggleClass('is-active');
      }, 300)
      return;
    }else if (label.attr("data-is-active") === "true") {
      const targetHeight = container.outerHeight();
      label.height(targetHeight);
      setTimeout(function () {
        label.height(0);
        label.attr("data-is-active", "false");
        $('.p-global-nav').toggleClass('is-active');
     }, 1);
      return;
    }

    $('body').toggleClass('is-active');
  });
  // 共通　ハンバーガーメニュー
  $('.p-global-nav__accordion').on("click", function () {
    $(this).toggleClass('is-active');
    $(this).next().toggleClass('is-active');
  });

  // トップページ　マーキー
  const childCnt = $('.p-front-info-box__link-wrap > span ').children().length;
  const marqueeSec = String(childCnt * 10);
  const animationPro = "marquee " + marqueeSec + "s linear infinite";
  $('.p-front-info-box__link-wrap > span ').css("animation",animationPro);


  // トップページ　インフォメーションタブ
  $('.l-front__information-box__info-nav__item.is-all').on("click", function () {
    $('.l-front__information-box__info-nav__item').removeClass('is-current');
    $('.l-front__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-front__information-box__news__item.is-all').addClass('is-current');
  });
  $('.l-front__information-box__info-nav__item.is-news').on("click", function () {
    $('.l-front__information-box__info-nav__item').removeClass('is-current');
    $('.l-front__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-front__information-box__news__item.is-news').addClass('is-current');
  });
  $('.l-front__information-box__info-nav__item.is-race').on("click", function () {
    $('.l-front__information-box__info-nav__item').removeClass('is-current');
    $('.l-front__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-front__information-box__news__item.is-race').addClass('is-current');
  });
  $('.l-front__information-box__info-nav__item.is-event').on("click", function () {
    $('.l-front__information-box__info-nav__item').removeClass('is-current');
    $('.l-front__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-front__information-box__news__item.is-event').addClass('is-current');
  });

  // 開催カレンダー　月選択
  $('.l-calendar__tab-list__item').on("click", function () {
    let window_w = window.innerWidth;
    let calendar_width = -1200;
    if(window_w < 581){
      calendar_width = -1 * (window_w - 20);
    }
    $('.l-calendar__tab-list__item').not('.is-no-select').removeClass('is-current');
    $(this).addClass('is-current');
    let movepx = calendar_width * $(this).attr("data-is-nth");
    $('.l-calendar__content').css('transform', 'translateX(' + movepx + 'px)');
  });

  // 開催カレンダー　月選択(セレクトボックス)
  $('.l-calendar__select-box__select').on('change', function () {
    let window_w = window.innerWidth;
    let calendar_width = -1200;
    if(window_w < 581){
      calendar_width = -1 * (window_w - 20);
    }
    $('.l-calendar__tab-list__item').not('.is-no-select').removeClass('is-current');
    $('.l-calendar__tab-list__item').attr("data-is-nth",$('.l-calendar__select-box__select option:selected').attr("data-is-nth")).addClass('is-current');
    let movepx = calendar_width * $('.l-calendar__select-box__select option:selected').attr("data-is-nth");
    $('.l-calendar__content').css('transform', 'translateX(' + movepx + 'px)');
  });

  // 所属選手一覧　ソート順選択
  $('.l-homeplayer__sort-btn.is-rank').on("click", function () {
    $('.l-homeplayer__sort-btn').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-homeplayer__list').removeClass('is-current');
    $('.l-homeplayer__list.is-rank').addClass('is-current');
  });
  $('.l-homeplayer__sort-btn.is-season').on("click", function () {
    $('.l-homeplayer__sort-btn').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-homeplayer__list').removeClass('is-current');
    $('.l-homeplayer__list.is-season').addClass('is-current');
  });

  // インフォメーション　インフォメーションタブ
  $('.l-newsinfo__tab-list__item.is-all').on("click", function () {
    $('.l-newsinfo__tab-list__item').removeClass('is-current');
    $('.l-newsinfo__ttl__content').removeClass('is-current');
    $('.l-newsinfo__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-newsinfo__ttl__content.is-all').addClass('is-current');
    $('.l-newsinfo__information-box__news__item.is-all').addClass('is-current');
  });
  $('.l-newsinfo__tab-list__item.is-news').on("click", function () {
    $('.l-newsinfo__tab-list__item').removeClass('is-current');
    $('.l-newsinfo__ttl__content').removeClass('is-current');
    $('.l-newsinfo__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-newsinfo__ttl__content.is-news').addClass('is-current');
    $('.l-newsinfo__information-box__news__item.is-news').addClass('is-current');
  });
  $('.l-newsinfo__tab-list__item.is-race').on("click", function () {
    $('.l-newsinfo__tab-list__item').removeClass('is-current');
    $('.l-newsinfo__ttl__content').removeClass('is-current');
    $('.l-newsinfo__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-newsinfo__ttl__content.is-race').addClass('is-current');
    $('.l-newsinfo__information-box__news__item.is-race').addClass('is-current');
  });
  $('.l-newsinfo__tab-list__item.is-event').on("click", function () {
    $('.l-newsinfo__tab-list__item').removeClass('is-current');
    $('.l-newsinfo__ttl__content').removeClass('is-current');
    $('.l-newsinfo__information-box__news__item').removeClass('is-current');
    $(this).addClass('is-current');
    $('.l-newsinfo__ttl__content.is-event').addClass('is-current');
    $('.l-newsinfo__information-box__news__item.is-event').addClass('is-current');
  });



});